import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({ data }) => {
  const { headline, image } = data.prismicHomePage.data;
  return (
    <Layout fullWidth>
      <SEO title="Home" />

      <div
        className="w-full h-90vh bg-cover bg-center bg-no-repeat flex items-end bg-sage"
        style={{ backgroundImage: `url(${image.url})` }}
      >
        <div className="max-w-6xl h-full mx-auto w-full relative">
          <h1 className="font-serif text-xl md:text-2xl leading-tight text-cream max-w-lg bg-dark-lighter px-6 py-4 absolute bottom-0 mb-24 mx-4">
            {headline.text}
          </h1>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    prismicHomePage {
      data {
        headline {
          text
        }
        image {
          url
          thumbnails {
            full_width {
              url
            }
          }
        }
      }
    }
  }
`;
